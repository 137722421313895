<template>
  <div id="pay">
    <van-nav-bar title="诚信保证金" left-arrow @click-left="goLastPage" @click-right="goSubPage" />
    <div class="payAmount">
      <van-field v-model="amount" type="number" label="充值金额" size="large" readonly :error-message="error_message" @input="amountChange">
        <template #right-icon>
          元
        </template>
      </van-field>
    </div>
    <div class="payAmount">
      <div class="payAmountWrap">
        <div class="payAmountItem flexCenter" v-for="(item,index) in amountArr" :key="index" @click="selectAmount(index)" :class="actTemp == index? 'act': ''">
          {{item}}
        </div>
      </div>
    </div>
    <div class="payType">
      <van-radio-group v-model="payType">
        <van-cell-group>

          <van-cell clickable @click="payType = '2'">
            <template #default>
              <div class="flex-left">
                <svg class="icon icon-size" aria-hidden="true">
                  <use xlink:href="#icon-zhifubao"></use>
                </svg>
                <span>支付宝支付</span>
              </div>
            </template>
            <template #right-icon>
              <van-radio name="2" checked-color="#FF7963" />
            </template>
          </van-cell>
          <van-cell clickable @click="payType = '1'">
            <template #default>
              <div class="flex-left">
                <svg class="icon icon-size" aria-hidden="true">
                  <use xlink:href="#icon-weixin"></use>
                </svg>
                <span>微信支付</span>
              </div>
            </template>
            <template #right-icon>
              <van-radio name="1" checked-color="#FF7963" />
            </template>
          </van-cell>
          <!-- <van-cell clickable @click="payType = '3'">
            <template #default>
              <div class="flex-left">
                <svg class="icon icon-size" aria-hidden="true">
                  <use xlink:href="#icon--"></use>
                </svg>
                <span>银行卡支付</span>
              </div>
            </template>
            <template #right-icon>
              <van-radio name="3" checked-color="#FF7963" />
            </template>
          </van-cell> -->
        </van-cell-group>
      </van-radio-group>
    </div>
    <div style="position: fixed; bottom: 0; left: 0; right: 0;z-index:99">
      <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block @click="submitPay">
        确认充值
      </van-button>
    </div>
  </div>
</template>
<script>
import TopBar from "@/components/home/TopBar.vue";
export default {
  data() {
    return {
      actTemp: 99,
      amount: "",
      error_message: "",
      payType: 1,
      out_trade_no: "",
      amountArr: ["0.01元", "1000元", "2000元", "3000元", "5000元"],
    };
  },
  components: {
    TopBar,
  },
  methods: {
    amountChange() {
      if (this.amount < 1000) {
        this.error_message =
          "保证金最低缴纳1000元,当然，测试阶段，你可以随便造";
      } else {
        this.error_message = "";
      }
    },
    submitPay() {
      if (!this.amount) {
        this.$toast("请填写充值金额");
      } else if (!this.payType) {
        this.$toast("请选择充值方式");
      } else {
        this.$http
          .post("/api/v2/worker/security_deposit/recharge", {
            method: this.payType,
            amount: this.amount,
          })
          .then((res) => {
            this.out_trade_no = res.data.out_trade_no;
            console.log(res.data.out_trade_no);
            window.location.href = `${this.yishifuHttp}/api/v2/worker/security_deposit/payment/${res.data.out_trade_no}`;
          });
      }
    },
    selectAmount(index) {
      this.actTemp = index;
      console.log(index, this.amountArr[index]);
      this.amount = this.amountArr[index];
    },
  },
  created() {},
};
</script>
<style lang="less">
#pay {
  .payAmount {
    margin-top: 2rem;
    box-sizing: border-box;
    .payAmountWrap {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      box-sizing: border-box;
      .payAmountItem {
        box-sizing: border-box;
        width: 30%;
        background: #fff;
        padding: 1.8rem 1rem;
        margin: 0.6rem;
        font-size: 1.6rem;
        border-radius: 5px;
        &.act {
          position: relative;
          box-shadow: 0 0 0 2px salmon;
        }
      }
      .act::after {
        content: "";
        position: absolute;
        right: 0.4rem;
        top: 0.3rem;
        display: block;
        // background: salmon;
        color: #fff;
        width: 1.5rem;
        height: 1.5rem;
        // background: url("https://cdn.yishifu.com/image/20201123180926MB3a4kgH.png")
        //   no-repeat center / cover;
      }
    }
  }
  .payType {
    margin-top: 2rem;
    .flex-left {
      display: flex;
      align-items: center;
    }
    .icon-size {
      width: 3rem;
      height: 3rem;
      padding-right: 2rem;
    }
  }
}
</style>